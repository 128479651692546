import './styles/fontLoader.scss';

const fontLoadDiv = window.document.createElement('div');

fontLoadDiv.innerHTML = '<span class="font-loader-bold-text" style="font-weight: 500;"></span><span style="font-weight: 400;"></span><span style="font-weight: 400; font-style: italic;"></span>';
fontLoadDiv.style.opacity = '0';
fontLoadDiv.style.position = 'absolute';

window.document.body.appendChild(fontLoadDiv);
fontLoadDiv.getBoundingClientRect();
window.document.body.removeChild(fontLoadDiv);
